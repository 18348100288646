import React from 'react'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'

import Container from '../styles/Container'

import cursorWhite from '../images/cursor-white.svg'
import backButtonOnDarkBlue from '../images/back-button-on-dark-blue.svg'
import handOnDarkBlue from '../images/hand-on-dark-blue.png'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #002C5E;
    background-image: none;
    // cursor: url(${cursorWhite}) 7 7, auto;
  }
  a, button, label, input, select, textarea {
    // cursor: url(${cursorWhite}) 7 7, auto !important;
  }
`

const Wrapper = styled.form`
  margin-top: 74px;
  @media (min-width: 680px) {
    margin-top: 100px;
  }
  @media (min-width: 1200px) {
    display: flex;
    align-items: flex-end;
    position: relative;
    margin-top: 120px;
    margin-bottom: 140px;
  }
`

const Left = styled.div``

const Right = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    right: 0px;
    transform: translate(200px, 80px);
  }
  @media (min-width: 1350px) {
    transform: translate(50px, 80px);
  }
`

const InputContainer = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  @media (min-width: 680px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  @media (min-width: 1200px) {
    padding-left: 70px;
  }
`

const Input = styled.input`
  width: 100%;
  padding: 34px 0px 34px 0px;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #979797;
  font-family: Dignitas, sans-serif;
  font-weight: 700;
  font-size: 44px;
  line-height: 47px;
  letter-spacing: 0.25px;
  color: #efe0d7;
  outline: none;
  ::placeholder {
    color: #efe0d7;
    opacity: 0.5;
  }
  :focus {
    border-bottom: 2px solid #efe0d7;
    padding: 34px 0px 33px 0px;
  }
  @media (min-width: 680px) {
    font-size: 86px;
    line-height: 94px;
    letter-spacing: 1.79px;
    padding: 34px 0px 40px 0px;
    margin-bottom: 14px;
    :focus {
      padding: 34px 0px 39px 0px;
    }
  }
  @media (min-width: 1200px) {
    max-width: 518px;
    padding: 30px 0px 50px 0px;
    font-size: 120px;
    line-height: 130px;
    letter-spacing: 2.5px;
    :focus {
      padding: 30px 0px 49px 0px;
    }
  }
`

const Arrow = styled.img`
  width: 14px;
  height: 8px;
  transform: rotate(180deg);
  margin-left: 8px;
  transition: all 0.15s ease-out 0s;
  @media (min-width: 1200px) {
    width: 21px;
    height: 8px;
  }
`

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 64px;
  @media (min-width: 680px) {
    margin-top: 113px;
  }
`

const Submit = styled.button`
  color: #f1e8d9;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.57px;
  font-weight: 300;
  @media (min-width: 680px) {
    margin-left: 30px;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0px;
    :hover {
      ${Arrow} {
        transform: rotate(180deg) translateX(-4px);
      }
    }
  }
  @media (min-width: 1200px) {
    margin-left: 0px;
  }
`

const HandImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const HandImg = styled.img`
  width: 265px;
  margin-top: -50px;
  @media (min-width: 680px) {
    margin-top: -100px;
    width: 390px;
  }
  @media (min-width: 1200px) {
    width: 400px;
    margin-top: -100px;
  }
`

const LogIn = () => {
  const handleSubmit = e => {
    e.preventDefault()
  }
  return (
    <>
      <GlobalStyle />
      <Container style={{ overflow: 'hidden' }}>
        <Wrapper onSubmit={handleSubmit}>
          <Left>
            <InputContainer>
              <Input type="email" placeholder="email" aria-label="Email" required />
            </InputContainer>
            <InputContainer>
              <Input type="password" placeholder="password" aria-label="Password" required />
            </InputContainer>
          </Left>
          <Right>
            <SubmitContainer>
              <Submit type="submit">
                log in <Arrow src={backButtonOnDarkBlue} alt="" />
              </Submit>
            </SubmitContainer>
            <HandImgContainer>
              <HandImg src={handOnDarkBlue} alt="" />
            </HandImgContainer>
          </Right>
        </Wrapper>
      </Container>
    </>
  )
}

export default LogIn
